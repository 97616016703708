import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LoginService} from "./login.service";
import {Router} from "@angular/router";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {HTTPService} from "../../shared/services/http/http.service";
import {User} from "./user";
import { Subscription } from 'rxjs';
import {GlobalModel} from "../../shared/services/state/global.model";
import {StorageService} from "../../shared/services/storage/storage.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {TooltipService} from "../../shared/services/tooltip/tooltip.service";
import {AreaalService} from "../../shared/services/areaal/areaal.service";
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'login-component',
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {

    @ViewChild('password', {static: false}) passwordInput:any;
    @ViewChild('username', {static: false}) usernameInput:any;
    
    public loginForm:UntypedFormGroup;
    private checkingLoginStatus:boolean = false;
    public failure:RequestFailure;
    private firstTimeVisible:boolean = true;
    public flippingLanguage:boolean = false;

    private isCapsOn:boolean = false;
    public _user:User = null;
    private subUser:Subscription;
    public maxSnowFlakes:number = 500;
    public totalSnowflakes:number = 12;
    public showXmasCard:boolean = false;
    private closeXmasCardCountdown:number = 2;
    private xmasCardCloseInterval: any;
    private xmasSong:any;

    public disableSubmitButton:boolean = false;

    constructor(public httpService:HTTPService, private loginService:LoginService, private formBuilder:UntypedFormBuilder, private router:Router,
                private cd:ChangeDetectorRef, public model:GlobalModel, private storage:StorageService, private ts:TranslateService,
                private renderer:Renderer2, private tooltipService:TooltipService, private areaalService:AreaalService, protected logger:LoggerService)
    {
        this.createForm();

        this.subUser = this.model.user.subscribe((value:User) => {
            this._user = value;
            this.cd.markForCheck();
        });

    }

    public makeMoreSnow():void{
        if(this.totalSnowflakes < this.maxSnowFlakes){
            this.totalSnowflakes = this.totalSnowflakes + 50;
            this.cd.detectChanges();
            if(this.totalSnowflakes < this.maxSnowFlakes){
                new Audio('./assets/sounds/jingle-bell.mp3').play().then();
                document.getElementById('snow-globe').classList.add('shake')
                setTimeout(() => (document.getElementById('snow-globe').classList.remove('shake')), 500)
            } else {
                this.xmasSong = new Audio('./assets/sounds/jingle-bells-short.mp3');
                this.xmasSong.play();
                this.showXmasCard = true;
                this.xmasCardCloseInterval = setInterval(() => {
                    this.closeXmasCardCountdown = this.closeXmasCardCountdown - 1;
                    if(this.closeXmasCardCountdown === 0) {
                        clearInterval(this.xmasCardCloseInterval);
                    }
                },1000)
            }
        }
    }

    public closeXmasCard():void{
        if(this.closeXmasCardCountdown === 0){
            this.xmasSong.pause();
            this.xmasSong.currentTime = 0;
            this.showXmasCard = false;
            this.totalSnowflakes = 12;
            this.closeXmasCardCountdown = 2;
            this.cd.detectChanges();
        }
    }

    getLogoPath(): string {
        if(this.model.isXmasMode()){
            return 'assets/img/luminizerlogo-xmas.png';
        } else {
            return '/assets/img/luminizerlogo.png';
        }
    }

    public handleFlippingLanguage(flippingLanguage:boolean){
        this.flippingLanguage = flippingLanguage;
    }

    public handlePasswordBlur(event:any){

        this.logger.log("[LoginComponent] " + "HANDLE handlePasswordBlur");
        this.tooltipService.destroyToolTip(this.passwordInput);
    }
    
    public handlePasswordKeyDown(event:any)
    {
        this.isCapsOn = event.getModifierState && event.getModifierState( 'CapsLock' );

        if (this.isCapsOn){

            //NOTE: non standaard huiliehuilie: https://developer.mozilla.org/en-US/docs/Web/API/msCapsLockWarningOff
            //document.msCapsLockWarningOff = true;

            this.tooltipService.createAndShowTooltip(
                this.renderer,
                this.passwordInput,
                this.ts.translate('warning.capslock')
            );
        }else{
            this.tooltipService.destroyToolTip(this.passwordInput);
        }
    }

    private createForm()
    {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        })
    }

    public handleClickLogin(event:MouseEvent)
    {
        //Handle component specific functionality, like showing a help message when the login fails
        //So don't do adjustments, that's the responsibility of the service
        this.disableSubmitButton = true;
        this.failure = null;

            this.logger.log("[LoginComponent] " + "Do login call");
            //When a call is performed, the app is blocked
            this.loginService.login(
                this.loginForm.value.username,
                this.loginForm.value.password,
                (returnURL:string) => {
                    this.logger.log("[LoginComponent] " + "Login success");
                },
                (failure:RequestFailure) => {

                    //App won't end up here. There is no failure for get-token or login
                    this.logger.log("[LoginComponent] " + "Login failed" , failure);

                    this.handleFailure(failure);
                },
                (error:RequestFailure) => {

                    //Also handles failure for login request

                    this.logger.log("[LoginComponent] " + "Login error" ,  error);

                    this.handleFailure(error);
                }
            )
    }

    private handleFailure(failure:RequestFailure)
    {
        this.loginForm.controls['password'].setValue("");
        this.passwordInput.nativeElement.focus();

        this.failure = failure;
        this.disableSubmitButton = false;
        setTimeout( () => {
            this.cd.detectChanges();
        });
    }
    
    public handleForgotPass(event:MouseEvent)
    {
        this.router.navigate([LuminizerRoutes.PASSWORD_FORGET_PAGE]);
    }
    
    public isLoginVisible(user:User):boolean{

        if (!this.checkingLoginStatus && !this.loginService.isLoggedInLocally(user) && !this.flippingLanguage){

            //TODO: iets mooiers maken hiervoor. Door de animatie die in het loginscherm zit is het element niet beschikbaar
            //Daarvoor wordt die timer ingezet
            if (this.firstTimeVisible){
                this.firstTimeVisible = false;

                setTimeout(() => {
                    if (this.usernameInput) {
                        this.usernameInput.nativeElement.focus();
                    }
                }, 500);
            }

            return true;
        }else{
            return false;
        }
    }

    ngOnInit():any
    {
        this.checkingLoginStatus = true;

        //logout locally so the app thinks it isn't logged in
        this.loginService.logoutLocal();

        // When public portal mode is active, log in with a special account
        if (this.model.publicPortalMode.value)
        {
            // NOTE: login is a special call with a weird error/failure flow (403 is OK, ect)
            this.loginService.login(LoginService.PUBLIC_PORTAL_USER, LoginService.PUBLIC_PORTAL_PASSWORD, (returnURL:string) => {
                this.logger.log("[LoginComponent] " + "SUCCESS: Logged in for public portal");

                let areaId:number = this.model.publicPortalArea.value;

                if (areaId > 0){
                    this.logger.log("[LoginComponent] " + "Start loading area " + areaId + " for public portal");
                    this.areaalService.loadAreaal(areaId, false, (json) => {
                        this.logger.log("[LoginComponent] " + "SUCCESS: Area " + areaId + " loaded for public portal");
                        this.model.publicPortalWelcomeText = json.user.publicPortalWelcomeText;
                        this.logger.log("[LoginComponent] "
                            + "Set publicPortalWelcomeText for area "
                            + areaId
                            + "; "
                            + this.model.publicPortalWelcomeText
                        );

                        //The right area is loaded, navigate to the public portal module
                        this.loginService.handleReturnURL(returnURL);

                    }, () => {
                        this.logger.log("[LoginComponent] " + "FAILURE: Could not load areaal " + areaId + " for public portal");
                    }, "");
                }else{
                    this.logger.log("[LoginComponent] " + "No valid areaid: "+ areaId + " ABORT PUBLIC PORTAL");
                }

            }, (failure:RequestFailure) => {
                this.logger.log("[LoginComponent] " + "FAILURE: Could not log in for public portal");
            }, (error:RequestFailure) => {
                this.logger.log("[LoginComponent] " + "FAILURE: Could not log in for public portal");
            })
        }else{
            this.logger.log("[LoginComponent] " + "Check if user is logged in on server");

            //When a call is performed, the app is blocked
            this.loginService.checkLogin(
                () => {
                    this.logger.log("[LoginComponent] " + "User [IS] logged in");
                    this.checkingLoginStatus = false;
                    this.cd.markForCheck()
                },
                (failure:RequestFailure) => {
                    this.logger.log("[LoginComponent] " + "User [IS NOT] logged in, ");
                    this.checkingLoginStatus = false;
                    this.cd.markForCheck()
                }
            )
        }
    }


    ngOnDestroy(): void
    {
        if (this.subUser){
            this.subUser.unsubscribe();
        }

    }
    
    public handleKeyDown($event: any) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            this.handleClickLogin($event);
        }
    }
}
