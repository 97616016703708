<ng-container [formGroup]="group">
  <form-component-label *ngIf="showLabel" [batchUpdateMode]="batchUpdateMode" [config]="config"
                        [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
  <div class="w-100 position-relative" #currentFormField>
    <date-input [inputType]="'time'"
                [disabled]="isControlDisabled()"
                [readonly]="isControlReadOnly()"
                [config]="config"
                [parentFormGroup]="group"
                [batchUpdateMode]="batchUpdateMode"
                [initialValue]="initialValue">
    </date-input>
  </div>
</ng-container>
