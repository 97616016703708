import {Component} from "@angular/core";
import {MapCoreComponent} from "./map-core.component";
import MapLayerManagerService from "./managers/layer-manager.service"
import MapDrawingManagerService from "./managers/drawing-manager.service";
import MapEventManagerService from "./managers/event-manager.service";
import MapUIManagerService from "./managers/ui-manager.service";
import MapDataManagerService from "./managers/data-manager.service";
import MapHelperManagerService from "./managers/helper-manager.service";
import {MapCoreV2Service} from "./map-core-V2.service";
import {drawingStates, IMapItem, IMousePosition, mapItemTypes} from "./managers/map-manager.interface";
import {MapSettings} from './managers/map.settings';
import {MapItem} from './managers/map-item';
import {Subscription} from "rxjs";
import {GlobalEvent} from "../../interfaces/global-event";
import {GlobalModel} from "../../services/state/global.model";
import {TranslateService} from "../../services/translate/translate.service";
import {AuthorizationService} from "../../services/authorization/authorization.service";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {LoggerService} from "../../services/logger/logger.service";
import {loggerLevel} from "../../services/logger/logger.interface";
import {WARN} from "@angular/compiler-cli/src/ngtsc/logging/src/console_logger";

@Component({
    selector: 'map-core-V2',
    standalone: true,
    template: ''
})
export class MapCoreV2Component {
    public map:google.maps.Map;

    private subOnGlobalEvent: Subscription = null;
    private subIsGridModeActive: Subscription = null;
    public subSelectedItems: Subscription = null;
    public isGridModeActive:boolean = false;
    public totalSelectedItems:number = 0;

    public mapSettings = MapSettings;

    public showInfoWindows:boolean = true;
    public showAnnotations:boolean = true;

    public originalSelectedMapTypeId:string = '';
    public originalSelectedPOIVisible:boolean = false;
    public isInSuperZoom:boolean = false;
    public mousePosition:IMousePosition = {
        isMouseOnCanvas:false,
        currentMouseLatLng:null
    };

    public mapLayerManagerService: MapLayerManagerService;
    public mapDrawingManagerService: MapDrawingManagerService;
    public mapEventManagerService: MapEventManagerService;
    public mapUIManagerService: MapUIManagerService;
    public mapDataManagerService: MapDataManagerService;
    public mapHelperManagerService: MapHelperManagerService;

    public bBoxMapItems: MapItem[] = [];
    public lastBBoxBounds: google.maps.LatLngBounds = null;
    public canDoBBoxCall = true;
    public bBoxListeners: google.maps.MapsEventListener[] = [];

    public drawState: drawingStates = null;
    public activeDrawingType:mapItemTypes = null;
    public polylineDrawingTail: google.maps.Polyline = null;

    public polylineDrawingHeadFixedLength:number = 0;
    public polylineWeight:number = this.mapSettings.DEFAULT_LINE_WEIGHT;
    public polylineColor:string = this.mapSettings.DEFAULT_LINE_COLOR;

    public mapItemEditPath:'add'|'remove' = null;
    public mapItemEditPathVertexPoint:number = null;
    public mapItemsAreClickable:boolean = false;

    public selectedMapItem:MapItem = null;

    //The hightlight line
    public highlightLine:google.maps.Polyline = new google.maps.Polyline({
        strokeColor: this.mapSettings.HIGHLIGHT_STROKE_COLOR,
        editable: false,
        clickable: false
    });
    //The drawing head
    public polylineDrawingHead: google.maps.Polyline = new google.maps.Polyline({
        strokeWeight: this.mapSettings.DRAW_HEAD_STROKE_WEIGHT,
        strokeColor: this.mapSettings.DRAW_HEAD_STROKE_COLOR,
        editable: true,
        clickable: false,
        zIndex:this.mapSettings.ZINDEX_POLYLINE_DRAW_HEAD
    });
    //The point drawing
    public markerDrawing: google.maps.Marker = new google.maps.Marker({
        icon: "/assets/img/lumicon/16x16/mst-blue.png",
        draggable:false,
        clickable:false,
        zIndex:this.mapSettings.ZINDEX_MARKER_DRAW
    });
    //The joint drawing
    public jointDrawing: google.maps.Marker = new google.maps.Marker({
        icon: {
            url: "/assets/img/lumicon/16x16/jnt-blue.png",
            anchor: new google.maps.Point(this.mapSettings.JOINT_ICON_CENTER, this.mapSettings.JOINT_ICON_CENTER),
        },
        draggable:false,
        clickable:false,
        zIndex:this.mapSettings.ZINDEX_MOF_DRAW
    });
    //The disabled joint drawing
    public jointDisabledDrawing: google.maps.Marker = new google.maps.Marker({
        icon: "/assets/img/lumicon/16x16/jnt-grey.png",
        draggable:false,
        clickable:false,
        zIndex:this.mapSettings.ZINDEX_MOF_DRAW
    });
    //Super zoom layer
    public superZoomLayer:google.maps.ImageMapType = new google.maps.ImageMapType({
        getTileUrl: function() {
            return '../../../../../assets/img/superzoom-tile.png';
        },
        tileSize: new google.maps.Size(265, 265),
        maxZoom: 27
    });

    constructor(private mapCoreV1:MapCoreComponent, public mapCoreV2Service:MapCoreV2Service, public model:GlobalModel, public ts: TranslateService, public auth: AuthorizationService, public globalAlertService: GlobalAlertService, protected logger:LoggerService){
        this.mapLayerManagerService = new MapLayerManagerService(this,logger);
        this.mapDrawingManagerService = new MapDrawingManagerService(this,logger);
        this.mapEventManagerService = new MapEventManagerService(this,logger);
        this.mapUIManagerService = new MapUIManagerService(this,logger);
        this.mapDataManagerService = new MapDataManagerService(this,logger);
        this.mapHelperManagerService = new MapHelperManagerService(this,logger);

        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS: //V1 map item
                case GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS: //V2 map item
                    this.mapLayerManagerService.removeMapItem(event.data.baseObjectId)
                    break;
                case GlobalEvent.EVENT_REMOVE_BASEOBJECT_BATCH_SUCCESS: //Batch delete
                    event.data.baseObjectIds.map(_baseObject => {
                        this.mapLayerManagerService.removeMapItem(Number(_baseObject.baseObjectId))
                    })
                    break;
                case GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT:
                    this.mapCoreV2Service.deleteUndergroundBaseObject(event.data.baseObjectData)
                    break;
            }
        });
    }

    public initializeMapCoreV2Component(map:google.maps.Map):void{

        this.logger.log('[Grid][MapCoreV2Component] ' + 'Initialize map core V2')
        this.map = map;
        this.mapLayerManagerService.initCreateLayers();
        this.mapEventManagerService.initGridModeIndependentEvents();


        this.subIsGridModeActive = this.model.gridDrawModeActive.subscribe((value: boolean) => {
            //Grid mode should be active, and mobile mode must be disabled
            if(value && !this.mapCoreV1.mobileMode){
                this.activeGridMode()
                this.deactivateBoundingBox();
            } else {
                this.deactivateGridMode()
                this.activateBoundingBox();
            }
        });
    }

    private activeGridMode():void{
        //Activate grid when not active
        if(!this.isGridModeActive){
            this.logger.log('[Grid][MapCoreV2Component] ' + 'Activate grid')
            this.isGridModeActive = true;
            this.mapUIManagerService.initBuildUi();
            this.mapEventManagerService.initCreateMapEvents();
            this.mapDrawingManagerService.initDrawingManager();
            this.mapHelperManagerService.handleAnnotations()
            this.mapCoreV1.showNoItemsWarning = false;
            this.mapCoreV1.allowMarkerLabels = false;
            this.saveOriginalMapTypeId();
            this.originalSelectedPOIVisible = this.mapCoreV1.POIVisible;
            this.mapCoreV1.map.mapTypes.set('superzoom',this.superZoomLayer)
            this.mapCoreV1.map.setOptions({
                disableDoubleClickZoom:true,
            })
        }
    }

    private deactivateGridMode():void{
        //Deactive grid when active
        if(this.isGridModeActive) {
            this.logger.log('[Grid][MapCoreV2Component] ' + 'Deactivate grid')
            this.isGridModeActive = false;
            this.mapUIManagerService.destroyUi();
            this.mapEventManagerService.destroyAllListeners()
            this.mapCoreV1.showNoItemsWarning = true;
            this.mapCoreV1.allowMarkerLabels = this.mapCoreV1.originalAllowMarkerLabels;
            this.mapCoreV1.map.setOptions({
                disableDoubleClickZoom: false,
            })
        }
    }

    public appendMapItemV1(mapItem){
        //Must keep reference to V1 marker so for V1 items, build object
        const mapItemObject: IMapItem = {
            baseObjects: mapItem.dataRef.baseObjects,
            label: mapItem.dataRef.label,
            icon: mapItem.dataRef.icon,
            layerId: this.mapSettings.V1_MARKER_LAYER_HARDCODED_ID,
            id: mapItem.dataRef.id,
            objectType:mapItem.dataRef.objectType,
            styleId: mapItem.dataRef.styleId,
            markerV1: mapItem
        }

        const mapItemV1 = new MapItem(this, mapItemObject, this.model);
        this.mapLayerManagerService.appendMapItem(mapItemV1);
    }

    public appendMapItemV2(mapItem){
        //Append V2 items.
        //Only append annotations when grid is active
        let appendMapItem:boolean = true;
        if(mapItem.objectType === 'annotation' && !this.isGridModeActive){
            appendMapItem = false;
        }
        if(appendMapItem){
            const mapItemV2 = new MapItem(this, mapItem, this.model);
            this.mapLayerManagerService.appendMapItem(mapItemV2);
        }
    }

    public appendCreatedMapItem(formPostResult:any):void{
        //Send mapItem to V1 so it can do whatever
        this.mapCoreV1.appendMarkers(formPostResult.mapItems)
    }

    public appendCreatedMapItemToTable(tableData:any):void{
        //Tigger global event with the newly created mapItem's tableData so it will be added to the table without needing to refresh the module
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_ADD_MAP_ITEM_TO_TABLE, {tableItems:tableData.tableItems}));
    }

    public toggleMapItemInfoWindowById(mapItemId:number, showInfoWindow:boolean):void{
        //Find the map item and show or hide the infoWindow
        const mapItem = this.mapLayerManagerService.getMapItemById(mapItemId)
        if(mapItem !== null){
            if(showInfoWindow){
                mapItem.showInfoWindowOnEdge()
            } else {
                mapItem.hideInfoWindow()
            }
        }
    }

    public saveOriginalMapTypeId():void{
        if(this.mapCoreV1.map.getMapTypeId() != 'superzoom'){
            this.originalSelectedMapTypeId = this.mapCoreV1.map.getMapTypeId();
        }
    }

    public closeInfoWindowForAllMapItems():void{
        this.mapLayerManagerService.closeInfoWindowForAllMapItems()
    }

    public clearLayers(){
        this.mapLayerManagerService.clearAllLayers();
    }

    public filterItemsV2(hiddenItems: (number | string)[], visibleItems: (number | string)[]):void{
        this.mapLayerManagerService.filterItemsLocal(hiddenItems,visibleItems)
    }

    public getAllNonV1MapItems():MapItem[]{
        return this.mapLayerManagerService.getAllNonV1MapItems()
    }
    public getAllNonV1SelectedMapItems():MapItem[]{
        return this.mapLayerManagerService.getAllNonV1SelectedMapItems()
    }

    public isMobileMode():boolean{
        return this.mapCoreV1.mobileMode;
    }

    public onSelectMarkersEmit(selectedPolylines:any, cmdKeyPressed:boolean):void{
        this.mapCoreV1.onSelectMarkers.emit({markers: selectedPolylines, cmdKeyPressed: cmdKeyPressed});
    }

    public setPOIVisible(visible: boolean):void{
        this.mapCoreV1.setPOIVisible(visible)
    }

    public handleMapItemMouseOver(mapItem:MapItem):void{
        if (!this.mapHelperManagerService.isInDrawingMode()){
            const convertedMapItem = {
                baseObjectId:mapItem.getBaseObjectId(),
                dataRef: {
                    lat: mapItem.getPath().at(-1).lat(),
                    lng: mapItem.getPath().at(-1).lng(),
                    label: mapItem.getLabel(),
                    icon: '',
                    id: String(mapItem.getId()),
                    baseObjects: [{
                        label: mapItem.getLabel(),
                        id: String(mapItem.getBaseObjectId())
                    }]
                }
            }
            this.mapCoreV1.handleMouseOverMarker(convertedMapItem)
        }
    }

    public activateBoundingBox():void{
        this.deactivateBoundingBox();
        this.bBoxListeners.push(google.maps.event.addListener(this.map, 'zoom_changed', (event) => {
            this.getBbox()
        }));

        this.bBoxListeners.push(google.maps.event.addListener(this.map, 'dragend', (event) => {
            this.getBbox()
        }));

        this.getBbox();
    }

    public deactivateBoundingBox():void{
        this.bBoxListeners.map(_listener => {
            google.maps.event.removeListener(_listener)
        })
        this.clearBoundBoxItems();
    }

    public clearBoundBoxItems():void{
        this.bBoxMapItems.map(_mapItem => {
            _mapItem.removeFromMap();
        });
        this.bBoxMapItems = []
    }

    public getBbox(){
        if (this.model.currentAreaal.getValue().id !== 568){
            this.logger.log('[Grid][MapCoreV2Component] ' + 'Skip bbox call for underground in this area')
            return;
        }
        const currentMapBounds = this.map.getBounds()
        if (currentMapBounds) {
            currentMapBounds.extend(google.maps.geometry.spherical.computeOffset(currentMapBounds.getNorthEast(), 50, 45))
            currentMapBounds.extend(google.maps.geometry.spherical.computeOffset(currentMapBounds.getSouthWest(), 50, 225))
        }

        if (this.map.getZoom() >= 17 && !this.isCurrentBoundsSameAsLast() && this.canDoBBoxCall){
            this.canDoBBoxCall = false;
            this.mapCoreV2Service.getBBoxItems(currentMapBounds).subscribe((formPostResult) => {
                const formPostResultConv = formPostResult as any;
                this.clearBoundBoxItems();
                formPostResultConv.mapItems.map(_mapItem => {
                    const mapItem = new MapItem(this, _mapItem, this.model)
                    mapItem.initGoogleObject();
                    if (this.model.currentModule.value === 'assets') {
                        mapItem.setClickable(true);
                    } else {
                        mapItem.setClickable(false);
                    }

                    this.bBoxMapItems.push(mapItem);
                })
                this.lastBBoxBounds = currentMapBounds

                if(!this.isCurrentBoundsSameAsLast()){
                    this.getBbox();
                }
                this.canDoBBoxCall = true;
            });
        }
    }

    public isCurrentBoundsSameAsLast():boolean {
        if(this.lastBBoxBounds === null){
            return false;
        } else {
            let currentSouthWest = this.map.getBounds().getSouthWest()
            let currentNorthEast = this.map.getBounds().getNorthEast()
            let lastSouthWest = this.lastBBoxBounds.getSouthWest()
            let lastNorthEast = this.lastBBoxBounds.getNorthEast()
            return (
                currentSouthWest.lat() !== lastSouthWest.lat() ||
                currentSouthWest.lng() !== lastSouthWest.lng() ||
                currentNorthEast.lat() !== lastNorthEast.lat() ||
                currentNorthEast.lng() !== lastNorthEast.lng()
            ) ? false : true;
        }
    }

    ngOnDestroy(): void {
        if (this.subOnGlobalEvent) {
            this.subOnGlobalEvent.unsubscribe();
        }
        if(this.subIsGridModeActive){
            this.subIsGridModeActive.unsubscribe();
        }
        if(this.subSelectedItems){
            this.subSelectedItems.unsubscribe();
        }
    }
}


