export class MapSettings {
    public static readonly FOCUSLAYER_BG_COLOR:string = '#ffedd9';
    public static readonly FOCUSLAYER_BG_OPACITY:number = 0.8;

    public static readonly HIGHLIGHT_STROKE_COLOR:string = '#000000';
    public static readonly HIGHLIGHT_LINE_WEIGHT_ADDITION:number = 2;

    public static readonly DEFAULT_SNAP_HIGHLIGHT_POINT_SIZE:number = 0.4;
    public static readonly DEFAULT_DISTANCE_TO_MAP_ITEM_TRIGGER:number = 20;
    public static readonly DEFAULT_DISTANCE_TO_LINE_TRIGGER:number = 10;
    public static readonly DEFAULT_PARALLEL_DISTANCE_TO_LINE_TRIGGER:number = 10;

    public static readonly DISTANCE_TO_MAP_ITEM_TRIGGER:number[][] = [[16,25],[17,20],[18,8],[19,6],[20,3],[21,1],[22,0.1],[23,0.08],[24,0.05],[25,0.05],[26,0.05],[27,0.05]] //[zoomlevel,distance(m)]
    public static readonly SNAP_HIGHLIGHT_POINT_SIZE:number[][] = [[13,25],[14,15],[15,6],[16,4],[17,2],[18,1],[19,0.4],[20,0.3],[21,0.3],[22,0.1],[23,0.08],[24,0.05],[25,0.02],[26,0.01],[27,0.005]] //[zoomlevel,radius size]
    public static readonly DISTANCE_TO_LINE_TRIGGER:number[][] = [[16,25],[17,20],[18,8],[19,6],[20,4],[21,2],[22,1],[23,0.5],[24,0.2],[25,0.1],[26,0.05],[27,0.05]] //[zoomlevel,distance(m)]
    public static readonly DISTANCE_PARALLEL_TO_LINE_TRIGGER:number[][] = [[19,0.2],[20,0.2],[21,0.2],[22,0.2],[23,0.2],[24,0.2],[25,0.2],[26,0.2],[27,0.2]] //[zoomlevel,distance(m)]
    public static readonly ZOOM_PREFIX_BUTTONS:(number|string)[][] = [[19,'10m'],[21,'2m'],[23,'50cm'],[27,'Max']] //[zoomlevel,button label]

    public static readonly MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS:number = 19;
    public static readonly MINIMUM_ZOOM_LEVEL_TO_TRIGGER_PARALLEL_LINES:number = 23;
    public static readonly DISTANCE_TO_DRAW_PARALLEL:number = 0.1;

    public static readonly JOINT_ICON_CENTER:number = 11;

    public static readonly DEFAULT_ZOOM_LEVEL_TO_ENTER_SUPER_ZOOM:number = 22;

    public static readonly PARALLEL_MINIMAL_SNAP_HEADING:number = 5;
    public static readonly PARALLEL_HEADING_DEVIATION:number = 180;

    public static readonly V1_MARKER_LAYER_HARDCODED_ID:number = -1;
    public static readonly ANNOTATION_LAYER_HARDCODED_ID:number = -2;

    public static readonly ANNOTATION_STROKE_COLOR:string = '#8bbeef';
    public static readonly ANNOTATION_STROKE_WEIGHT:number = 2;
    public static readonly ANNOTATION_ZINDEX:number = 25;

    public static readonly DRAW_HEAD_STROKE_COLOR:string = '#555555';
    public static readonly DRAW_HEAD_STROKE_WEIGHT:number = 2;

    public static readonly KEYCODES_DRAW_CANCEL:number[] = [27]; //esc
    public static readonly KEYCODES_DRAW_UNDO:number[] = [8]; //backspace
    public static readonly KEYCODES_MULTI_SELECT:number[] = [93,17]; //meta & ctrl
    public static readonly KEYCODES_ZOOM_LEVEL:number[] = [49,97,50,98,51,99,52,100] //number 1-4 & number 1-4 numpad
    public static readonly KEYCODES_SAVE_CHANGES:number[] = [13]; //enter

    public static readonly ZINDEX_FOCUSLAYER:number = -3;
    public static readonly ZINDEX_POLYLINE_DRAW_TAIL:number = 20;
    public static readonly ZINDEX_POLYLINE_DRAW_HEAD:number = 21;
    public static readonly ZINDEX_MARKER_DRAW:number = 21;
    public static readonly ZINDEX_MOF_DRAW:number = 21;

    public static readonly DEFAULT_LINE_WEIGHT:number = 1;
    public static readonly DEFAULT_LINE_COLOR:string = '#000000';
}
