<div class="device-mapping-container">
    <div class="selected-device-info-container" [ngClass]="{'open':infoWindowOpen}">
        <div class="inner">
            <div class="d-flex justify-content-center" *ngIf="infoWindowLoading">
                <div class="loader-smaller"></div>
            </div>
            <div *ngIf="!infoWindowLoading && selectedMapItemInfo">
                <div class="header mb-2 d-flex justify-content-between">
                    <h5>{{'device-mapping.title' | translate}}</h5>
                    <i class="material-icons" (click)="closeMarkerInfoWindow()">close</i>
                </div>
                <div class="info">
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-id' | translate}}</label>
                        <div>{{selectedMapItemInfo.id}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-code' | translate}}</label>
                        <div>{{selectedMapItemInfo.code}}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <label>{{'device-mapping.label-type' | translate}}</label>
                        <div>{{selectedMapItemInfo.type}}</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" *ngIf="selectedMapItemInfo.options.length > 0">
                        <label>{{selectedMapItemInfo.optionsLabel}}:</label>
                        <lumi-select class="map-item-select"
                                     [options]="selectedMapItemInfo.options"
                                     [disabled]="selectedMapItemInfo.options.length == 1"
                                     [selectedOptions]="selectedMapItemInfo.options.length == 1 ? selectedMapItemInfo.options : []"
                                     [showOptionFilter]="selectedMapItemInfo.options.length > 10"
                                     (onOptionsSelect)="setSelectedDropdownOption($event)">
                        </lumi-select>
                    </div>
                    <div class="d-flex align-items-center description" *ngIf="selectedMapItemInfo.description"><i class="material-icons">warning</i> <label [innerHTML]="selectedMapItemInfo.description"></label></div>
                </div>
            </div>
        </div>
    </div>
    <div #mapContainer id="mapContainer" class="w-100"></div>
</div>
<div class="action d-flex justify-content-end mt-4">
    <lumi-button
            [label]="'Annuleren' | translate"
            [rank]="'tertiary'"
            [size]="'large'"
            (click)="closePopupClick()"
    ></lumi-button>
    <lumi-button
            *ngIf="showUnmapBtn"
            [label]="'button.unlink-asset' | translate"
            [rank]="'secondary'"
            [size]="'large'"
            (click)="unMapDevice()"
    ></lumi-button>
    <lumi-button
            [label]="'button.submit-asset-mapping' | translate"
            [disabled]="isMapBtnDisabled"
            [rank]="'secondary'"
            [size]="'large'"
            (click)="mapDevice()"
    ></lumi-button>
</div>
