import {
    Component,
    ElementRef,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    AfterViewInit,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import {MapItem} from './map-device-mapping-mapitem.component';
import {Subscription} from "rxjs";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {IDeviceInfo, IMapItem, IMarker} from './map-device-mapping.interface';
import {MapDeviceMappingService} from './map-device-mapping.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {finalize, take} from "rxjs/operators";
import {GlobalEvent} from "../../../../shared/interfaces/global-event";
import {GlobalModel} from "../../../../shared/services/state/global.model";
import {HTTPService} from "../../../../shared/services/http/http.service";
import {ButtonCode} from "../../global-popup";
import {GlobalAlertService} from "../../global-alert.service";

@Component({
    selector: 'map-device-mapping',
    templateUrl: 'map-device-mapping.component.html',
    styleUrls: ['map-device-mapping.component.scss']
})
export class MapDeviceMappingComponent implements OnInit, AfterViewInit {
    @Input() popupData: IMarker = null;
    @Output() onChangeLoadingState = new EventEmitter<boolean>();
    @Output() closePopup = new EventEmitter<boolean>();
    @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;

    private subscriptions: Subscription[] = [];
    public map: google.maps.Map;
    public assetMarker: MapItem = null;
    public bBoxItems: MapItem[] = [];
    private eventDragend:google.maps.MapsEventListener = null;
    private eventZoomChanged:google.maps.MapsEventListener = null;
    private selectedBaseObjectId:number = null;
    public showUnmapBtn:boolean = false;
    public isMapBtnDisabled:boolean = true;

    public infoWindowOpen:boolean = false;
    public infoWindowLoading:boolean = true;
    public selectedMapItem:IMapItem = null;
    public selectedMapItemInfo:IDeviceInfo = null;

    constructor(protected httpService: HTTPService, protected logger:LoggerService, private mapDeviceMappingService:MapDeviceMappingService, private model:GlobalModel, public ts: TranslateService, private cd: ChangeDetectorRef, private globalAlertService: GlobalAlertService) {}

    ngOnInit() {
        this.showUnmapBtn = this.popupData.showUnMapButton;
    }

    ngAfterViewInit() {
        this.logger.log('[MapDeviceMapping] ' + 'Initialize map')
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: true,
            mapTypeId: 'roadmap',
            backgroundColor: '#f9f9f9',
            styles: [{featureType:"poi", elementType:"labels", stylers:[{visibility:"off"}]},{featureType:"transit",stylers:[{visibility:"off"}]}],
            zoom:21,
        });

        //Add event for drag
        this.eventDragend = google.maps.event.addListener(this.map, 'dragend', () => {
            this.getBbox()
        })
        //Add event for zoom
        this.eventZoomChanged = google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.getBbox()
        })

        if(this.popupData && this.popupData?.baseObjectId){
            this.initSourceMarker();
            //Use timeout to prevent the bounds from returning undefined
            setTimeout(()=>{
                this.getBbox();
            })
        }

        this.onChangeLoadingState.emit(false)
    }

    private initSourceMarker(){
        this.logger.log('[MapDeviceMapping] ' + 'Initialize source marker')
        this.assetMarker = new MapItem(this)
        this.assetMarker.initGoogleObject(this.popupData.baseObjectId,this.popupData.icon+".gif", this.popupData.lat, this.popupData.lng, null,false, true, false)
        this.map.setCenter(new google.maps.LatLng(this.popupData.lat, this.popupData.lng))
    }

    public selectMarker(mapItem: IMapItem,loadInfo:boolean): void {
        mapItem.marker.setIcon(mapItem.icon.replace(".png", '-sel.png'))
        this.selectedMapItem = mapItem;
        this.selectedBaseObjectId = mapItem.baseObjectId;
        if(loadInfo){
            this.openMarkerInfoWindow();
        }
    }

    private openMarkerInfoWindow():void{
        this.infoWindowLoading = true;
        this.infoWindowOpen = true;
        this.isMapBtnDisabled = true;
        this.cd.detectChanges();

        this.mapDeviceMappingService.getSelectedDeviceInfo(this.popupData.baseObjectId, this.selectedBaseObjectId).subscribe((result) => {
            this.selectedMapItemInfo = result as any;

            if(this.selectedMapItemInfo.canMap){
                if(this.selectedMapItemInfo.options.length == 1){
                    this.selectedMapItemInfo.selectedOptionId = this.selectedMapItemInfo.options[0].id;
                    this.isMapBtnDisabled = false;
                }
            }

            this.infoWindowLoading = false;
            this.cd.detectChanges();
        });
    }

    public closeMarkerInfoWindow():void{
        this.clearSelectedMarkers();
        this.infoWindowOpen = false;
        this.isMapBtnDisabled = true;
        this.selectedMapItemInfo = null;
        this.cd.detectChanges();
    }

    public closePopupClick():void{
        this.closePopup.emit();
    }

    public setSelectedDropdownOption($event:any):void{
        this.selectedMapItemInfo.selectedOptionId = Number($event[0].id);
        if(this.selectedMapItemInfo.canMap){
            this.isMapBtnDisabled = false;
        }
    }

    public unMapDevice():void{
        this.globalAlertService.addPopup(this.ts.translate("device-mapping.unlink-warning-title"), this.ts.translate("device-mapping.unlink-warning-label"),
            [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.ts.translate("button.unlink-asset"), code:ButtonCode.OK,
                    callback:() => {
                        this.closePopup.emit();
                        this.mapDeviceMappingService.unmapDevice(this.popupData.baseObjectId).pipe(
                            take(1),
                            finalize(() => {
                                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: this.popupData.baseObjectId}));
                            })
                        ).subscribe();
                    },
                    isPrimary:false}
            ], () => {})
    }

    public mapDevice():void{
        if(this.selectedMapItemInfo.selectedOptionId !== null){
            this.subscriptions.push(this.mapDeviceMappingService.mapDevice(this.popupData.baseObjectId,this.selectedBaseObjectId,this.selectedMapItemInfo.selectedOptionId).subscribe((formPostResult) => {
                this.closePopup.emit();
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: this.popupData.baseObjectId}));
            }));
        }
    }

    private getBbox(){
        const currentMapBounds = this.map.getBounds()
        if (currentMapBounds) {
            this.subscriptions.push(this.mapDeviceMappingService.getBBoxItems(currentMapBounds).subscribe((formPostResult) => {
                const formPostResultConv = formPostResult as any;
                this.clearBoundBoxItems();
                if(formPostResultConv.mapItems.filter(_x => {return _x.id == this.selectedBaseObjectId}).length == 0){
                    this.selectedBaseObjectId = null;
                    this.closeMarkerInfoWindow();
                }
                formPostResultConv.mapItems.map((_marker: IMarker) => {
                    const mapItem = new MapItem(this)
                    mapItem.initGoogleObject(_marker.id, _marker.icon+'.png', _marker.lat, _marker.lng, _marker.label, true, false, this.selectedBaseObjectId === _marker.id);
                    this.bBoxItems.push(mapItem);
                })
            }));
        }
    }

    private clearBoundBoxItems():void{
        this.bBoxItems.map(_targetItem => {
            _targetItem.removeFromMap()
        });
        this.bBoxItems = []
    }

    public clearSelectedMarkers():void{
        this.selectedBaseObjectId = null;
        this.bBoxItems.map(_mapItem => {
            _mapItem.isSelected = false;
            _mapItem.mapItem.marker.setIcon(_mapItem.mapItem.icon.replace("-sel", ''))
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        google.maps.event.removeListener(this.eventDragend)
        google.maps.event.removeListener(this.eventZoomChanged)
    }
}


