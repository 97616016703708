import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component ({
    selector: 'login-footer',
    templateUrl: 'login-footer.component.html',
    styleUrls: ['login-footer.component.scss']
})

export class LoginFooterComponent {
    @Output() onClickSnowGlobe: EventEmitter<any> = new EventEmitter();
    @Input() public explodeSnowGlobe: boolean = false;
    @Input() public showSnowGlobe: boolean = false;
    constructor(){}

    public emitClick($event: MouseEvent) {
        this.onClickSnowGlobe.emit($event);
    }
}
