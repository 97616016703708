/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    Component, ViewChild, ViewChildren, QueryList, ElementRef, ChangeDetectionStrategy,
    NgZone, ChangeDetectorRef, OnDestroy, EventEmitter, Output, AfterViewInit, Input,
} from '@angular/core';
import {LoginService} from '../../wrapper/login/login.service';
import {AppSettings} from '../../app.settings';
import {DropDownData} from './dropdown-data';
import {GlobalModel} from '../../shared/services/state/global.model';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {MenuService} from './menu.service';
import {Areaal} from '../../shared/interfaces/areaal';
import {Router} from '@angular/router';
import {ChangeableComponent} from '../../shared/components/changeable/changeable.component';
import {Subscription} from 'rxjs';
import {TooltipService} from '../../shared/services/tooltip/tooltip.service';
import {MenuItem} from './menu-item/menu-item';
import {TimerService} from '../../shared/services/timer/timer.service';
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {StorageService} from '../../shared/services/storage/storage.service';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'menu-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'menu.component.html'
})

export class MenuComponent extends ChangeableComponent implements AfterViewInit, OnDestroy {

    //BUG FIX: Input focus bug for touch devices running IE11: solution is to use mousedown instead of click
    //https://stackoverflow.com/questions/39439115/how-to-execute-click-function-before-the-blur-function

    @ViewChild('areaalDropDown', {static: false}) areaalDropDown: any;
    @ViewChild('searchInput', {static: false}) searchInput: any;
    @ViewChild('collapseMenuButton', {static: false}) collapseMenuButton: any;
    @ViewChild('searchItemList', {static: false}) searchItemList: any;
    @ViewChild('menu', {static: false}) menu: any;
    @ViewChild('containerElement', {static: false}) containerElement: any;
    @ViewChildren('searchItems') searchItems: QueryList<any>;

    @Input() public isSimpleMenu: Boolean = false;

    @Output() onResize: EventEmitter<any> = new EventEmitter();

    private static readonly SEARCHBOX_VISIBLE_ITEM_COUNT: number = 3;
    private static readonly VISIBLE_RECENT_AREALEN_COUNT: number = 3;
    private static readonly SELECTED_SEARCH_ITEM_CLASS: string = 'selected-searchitem';


    filteredSearchItems: any = [];
    private timesKeyDownPressed: number = 0;
    //private autoSelectFirstItem = false;

    private isLoading: boolean = false;
    showAreaalMenu: boolean = false;

    _arealen: Areaal[] = [];
    _recentArealen: Areaal[] = [];
    _mobileMode: boolean = false;
    _mainMenuItems: MenuItem[] = [];

    private subMobileMode: Subscription;
    private subRecentArealen: Subscription;
    private subArealen: Subscription;
    private subPendingCallPaths: Subscription;
    private subMainMenuItems: Subscription;

    constructor(
        private loginService: LoginService,
        public model: GlobalModel,
        private areaalService: AreaalService,
        private globalAlertService: GlobalAlertService,
        private httpService: HTTPService,
        private menuService: MenuService,
        protected elementRef: ElementRef,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        private router: Router,
        private toolTipService: TooltipService,
        private timerService: TimerService,
        private storageService: StorageService,
        protected logger:LoggerService
    ) {
        super(elementRef);

        this.subRecentArealen = model.recentArealen.subscribe((arealen: Areaal[]) => {
            //Don't forget to unsubscribe
            this._recentArealen = arealen;
        });

        this.subArealen = model.arealen.subscribe((arealen: Areaal[]) => {
            //Don't forget to unsubscribe
            this._arealen = arealen;
            this.resetFilteredSearchItems();
        });

        this.subMainMenuItems = this.model.mainMenuItems.subscribe((value: MenuItem[]) => {
            this._mainMenuItems = value;
            this.cd.markForCheck();

            setTimeout(() => {
                this.dispatchMenuHeight();
            });
        });
    }

    ngAfterViewInit(): void {
        this.updateMobileMode();
        this.updateTabletMode();
        this.updateLaptopMode();

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this._mobileMode = value;
            this.cd.detectChanges();
        });

        this.subPendingCallPaths = this.httpService.pendingCallPaths.subscribe(() => {
            //Don't forget to unsubscribe

            //Refresh the loading state by checking the pending calls
            this.cd.detectChanges();
        });
    }

    private dispatchMenuHeight(): void {
        if (this.containerElement) {
            this.onResize.emit(this.containerElement.nativeElement.offsetHeight);
        }
    }

    ngOnDestroy() {
        this.subMobileMode.unsubscribe();
        this.subRecentArealen.unsubscribe();
        this.subArealen.unsubscribe();
        this.subMainMenuItems.unsubscribe();
    }

    getUserName(): string {
        let result: string = '';

        if (this.model.user.value) {
            result = this.model.user.value.firstName + (this.model.user.value.infix?' '+this.model.user.value.infix+' ':' ') + this.model.user.value.surname;
        }

        return result;
    }

    getFullAreaName(): string {
        return (this.model.currentAreaal !== null && this.model.currentAreaal.value) ? this.model.currentAreaal.value.label : '';
    }

    getShortenedAreaName(): string {
        let areaName: string = this.getFullAreaName();

        // Shorten parts of large area names
        let maxLength: number = 22;
        if (areaName.length > maxLength) {
            areaName = areaName.replace('Gemeente', 'Gem.');
            areaName = areaName.replace('Provincie', 'Prov.');
            areaName = areaName.replace('Development', 'Dev.');

            if (areaName.length > maxLength) {
                // Still too long? remove prefix entirely
                areaName = areaName.replace('Gem.', '');
                areaName = areaName.replace('Prov.', '');

                // Name is still too long, cut it of at the start
                if (areaName.length > maxLength) {

                    // Take the last maxLength-3 chars and add ...
                    //areaName = areaName.substr(areaName.length - (maxLength - 3));
                    //areaName = "..." + areaName;

                    // Take the first maxLength-3 chars and add ...
                    areaName = areaName.substr(0, (maxLength - 3));
                    areaName = areaName + '...';
                }
            }
        }

        return areaName;
    }

    handleMenuAction() {
        this.collapseMenu();


        // NOTE: Disabled: it causes a routing change after the new menu-link-routing has been activated, setting origin=null on urls that don't even use origin ect.
        //  Disadvantage: Click a menu link again, wont reset the current page to its starting state. i.e: when your at assets management state=map, and click the link in the menu, you'll stay at state=map. (before, you went to state=startingstage)
        //this.model.mobileStateBack.next(1000); //Duizendmiljoentriljoen
    }

    handleBackButton(): void {

        //Hide all tooltips, else the tooltips will stay visible on mobile when switching state
        this.toolTipService.hideAllTooltips();

        this.model.mobileStateBack.next(1);
    }

    handleClickWindow(): void {
        //this.logger.log("[MenuComponent] " + "handle click window");

        //Prevent unnecessary change detection
        if (this.showAreaalMenu) {
            this.showAreaalMenu = false;
            this.cd.detectChanges();
        }
    }

    handleClickMenuDropdown(event: any): void {
        //Clicking the scrollbar removes the focus, so set focus to the field on click
        if (this.searchInput) {
            this.searchInput.nativeElement.focus();
        }

        //When clicking inside the menu, don't trigger window>click. That will hide the menu
        event.stopPropagation();
    }

    public closeMenu(): void {
        //TODO: non-jQuery oplossing

        //NOTE: collapse werkt alleen na casten naar any
        (<any>$('.navbar-collapse')).collapse('hide');
    }

    //Collapse the mobile-menu
    private collapseMenu(): void {
        this.menu.nativeElement.classList.remove('show');
    }

    private getAreaalDropDownCopy() {
        //Not supported by older browsers
        //return Object.assign([], this.model.arealen);

        return this._arealen.slice();
    }

    //IE 11 fix for re-focussing of searchfield after clicking the scrollbar
    //Triggers on mouseup AND mousedown (guess what, that's another fix, for a specific IE 11 version...)
    handleClickListContainer(): void {
        setTimeout(() => {
            if (this.searchInput) {
                this.searchInput.nativeElement.focus();
            }
        }, 0);
    }

    private resetFilteredSearchItems(): void {
        this.filteredSearchItems = this.getAreaalDropDownCopy();
    }

    filterAreaalDropdown() {
        let value: string = '';

        if (this.searchInput) {
            value = this.searchInput.nativeElement.value;
        }

        if (!value || value == '') {
            this.resetFilteredSearchItems(); //when nothing is typed
        }
        else {

            let values: any[] = value.split(' ');

            this.filteredSearchItems = this.getAreaalDropDownCopy()
                .filter((item: DropDownData) => {

                        let numTerms: number = values.length;
                        let numMatches: number = 0;

                        values.forEach((term: string) => {
                            if (item.label.toLowerCase().indexOf(term.toLowerCase()) > -1) {
                                numMatches++;
                            }
                        });

                        return numTerms == numMatches;
                    },
                );
        }

        this.cd.detectChanges();
    }

    private resetSearchBox() {
        //Reset the searchbox
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }

        //Scroll back to top
        this.searchItemList.nativeElement.scrollTop = 0;

        this.resetFilteredSearchItems();
        this.areaalDropDown.nativeElement.classList.remove('show');

        this.resetSelectedSearchItem();
    }

    handleSearchKey(event: any) {

        //Key enter
        if (event.keyCode == 13) {
            if (this.filteredSearchItems.length > 0) {

                this.showAreaalMenu = false;
                this.cd.detectChanges();

                //Switch area. Run in the zone, else the binding won't trigger properly later on
                this.ngZone.run(() => {

                    let searchItemIndex: number = Math.max(this.timesKeyDownPressed - 1, 0);
                    this.switchArea(this.filteredSearchItems[searchItemIndex]);
                });

                //Prepare UI for new input
                this.resetSearchBox();
            }

            //Key arrow down
        }
        else if (event.keyCode == 40) {
            if (this.timesKeyDownPressed < this.searchItems.length) {

                //Deactivate the previous item
                if (this.timesKeyDownPressed > 0) {
                    //this.searchItems.toArray()[this.timesKeyDownPressed - 1].nativeElement.childNodes.item(0).classList.remove(MenuComponent.SELECTED_SEARCH_ITEM_CLASS);
                    this.deselectCurrentItem();
                }
                else {
                    this.timesKeyDownPressed = 0;
                }

                this.searchItems.toArray()[this.timesKeyDownPressed].nativeElement.childNodes.item(0).classList.add(MenuComponent.SELECTED_SEARCH_ITEM_CLASS);
                this.timesKeyDownPressed++;
            }
            else {
                this.resetSelectedSearchItem();
            }

            //Key arrow up
        }
        else if (event.keyCode == 38) {

            if (this.timesKeyDownPressed > 1) {
                //Deactivate the previous item
                this.deselectCurrentItem();
                this.searchItems.toArray()[this.timesKeyDownPressed - 2].nativeElement.childNodes.item(0).classList.add(MenuComponent.SELECTED_SEARCH_ITEM_CLASS);
                this.timesKeyDownPressed--;
            }
            else {
                this.resetSelectedSearchItem();
            }
        }
        else {
            this.resetSelectedSearchItem();
        }

        this.scrollToSelectedItem();
    }

    private deselectCurrentItem() {
        if (this.timesKeyDownPressed > 0) {
            this.searchItems.toArray()[this.timesKeyDownPressed - 1].nativeElement.childNodes.item(0).classList.remove(MenuComponent.SELECTED_SEARCH_ITEM_CLASS);
        }
    }

    handleClickMenuItemAbout(): void {
        this.handleMenuAction();

        this.globalAlertService.addPopupAboutUs(() => {
        }, () => {
        });
    }

    private scrollToSelectedItem() {
        //Autoscroll to selected item
        if (this.searchItems.length > 0) {
            //Scroll downwards if the selected item is higher than the number visible in thelist
            //Use the getBoundingClientRect function to get a bounding box WITH floatingpoint numbers, not rounded values
            let elementHeight: number = this.searchItems.toArray()[0].nativeElement.childNodes.item(0).getBoundingClientRect().height;
            let scrollPosition: number = this.searchItemList.nativeElement.scrollTop;
            let itemPosition: number = (this.timesKeyDownPressed - 1) * elementHeight;
            let visibleItemsHeight: number = ((this.SEARCHBOX_VISIBLE_ITEM_COUNT - 1) * elementHeight);

            if (itemPosition < scrollPosition) {
                this.searchItemList.nativeElement.scrollTop = itemPosition;
            }
            else if (itemPosition > (scrollPosition + visibleItemsHeight)) {
                this.searchItemList.nativeElement.scrollTop = itemPosition - visibleItemsHeight;
            }
        }
    }

    private resetSelectedSearchItem() {
        if (this.timesKeyDownPressed > 0) {
            this.searchItems.toArray()[this.timesKeyDownPressed - 1].nativeElement.childNodes.item(0).classList.remove(MenuComponent.SELECTED_SEARCH_ITEM_CLASS);
        }
        this.timesKeyDownPressed = 0;
    }

    //When you click the areaal button in topmenu
    handleClickAreaal(event: any) {
        this.logger.log('[MenuComponent] ' + 'handle click areaal');

        //If the menu is visible already
        this.showAreaalMenu = !this.showAreaalMenu;
        // if (this.showAreaalMenu) {
        // }

        //Clicking the scrollbar removes the focus, so set focus to the field on click
        if (this.searchInput) {
            this.searchInput.nativeElement.focus();
        }

        event.stopPropagation();
    }

    handleSelectAreaal(item: DropDownData, event: any) {
        event.preventDefault();
        event.stopPropagation();

        this.handleMenuAction();
        this.showAreaalMenu = false;

        // Possible change detection fix 3
        this.ngZone.run(() => {
            this.switchArea(item);
        });

        //Prepare UI for new input
        this.resetSearchBox();

        this.cd.detectChanges();
    }

    private switchArea(newArea: DropDownData) {
        if (newArea && !this.isLoading) {
            this.isLoading = true;
            this.cd.detectChanges();

            this.logger.log('[MenuComponent] ' + 'Switch area to ' + newArea.label, newArea);
    
            // reset the dashboard states
            this.logger.log('[MenuComponent] ' + 'resetting dashboards');
            this.storageService.setValue(StorageService.KEY_LAST_USED_DASHBOARD, null);
            this.model.currentDashboard.next(null);
            
            this.areaalService.loadAreaal(newArea.id, true,
                (json: any) => {
                    this.isLoading = false;
                    this.timerService.idleTimerSeconds = json.user.autoLogout * 60;
                    this.timerService.stopTimer();
                    this.cd.detectChanges();
                },
                (failure: RequestFailure) => {
                    this.globalAlertService.addAlertFailure(failure);
                    this.isLoading = false;
                    this.cd.detectChanges();
                },
                LuminizerRoutes.INITIAL_PAGE,
            );
        }
    }

    handleLogout() {

        this.loginService.logout(false,
            () => {
                //User is successfully logged out and redirected to login
            },
            () => {
                //Logging out has failed
            },
        );
    }

    //TODO: als er nog meer specifieke properties bij layouttype komen moet dit generieker
    getLogoPath(): string {
        if (AppSettings.LAYOUT_TYPE == AppSettings.LAYOUT_TYPE_STEDIN) {
            return 'assets/img/sms-stedin wit.png';
        } else if(this.model.isXmasMode()){
            return 'assets/img/logo-luminizer-xmas.png';
        } else {
            return '/assets/img/luminzer_logo_diapositief_CMYK h30 - NoVersion.png';
        }
    }

    // This could also be done in CSS.
    getLogoMargin(): number {
        if (AppSettings.LAYOUT_TYPE == AppSettings.LAYOUT_TYPE_STEDIN) {
            return 0;
        }
        else {
            return -0.3;
        }
    }

    getAreaalMenuListClasses(): string {
        return 'nav-item dropdown btn-group';
    }

    getAreaalMenuItemClasses(): string {
        return 'nav-link d-flex align-items-center px-2 waves-effect waves-light';
    }

    getRouteActiveClass(url: string) {
        return this.router.isActive(url, false) ? 'active' : '';
    }

    callsPending(): boolean {
        //If angular/flex calls are pending or flex has no initialized menu yet
        return this.httpService.hasPendingCall();
    }

    private updateMobileMode(): void {
        let currentWidth: number = $(window).width();
        let isMobileScreen = currentWidth < AppSettings.MOBILE_BREAKPOINT_WIDTH;
        this.model.mobileMode.next(isMobileScreen);
    }

    private updateTabletMode(): void {
        let currentWidth: number = $(window).width();
        let isTabletScreen = currentWidth < AppSettings.TABLET_BREAKPOINT_WIDTH && currentWidth >= AppSettings.MOBILE_BREAKPOINT_WIDTH;
        this.model.tabletMode.next(isTabletScreen);
    }

    private updateLaptopMode(): void {
        let currentWidth: number = $(window).width();
        let isLaptopScreen = currentWidth < AppSettings.LAPTOP_BREAKPOINT_WIDTH && currentWidth >= AppSettings.TABLET_BREAKPOINT_WIDTH;
        this.model.laptopMode.next(isLaptopScreen);
    }

    handleResize() {

        this.updateMobileMode();

        this.updateTabletMode();

        this.updateLaptopMode();

        this.dispatchMenuHeight();

        this.cd.detectChanges();
    }

    get SEARCHBOX_VISIBLE_ITEM_COUNT() {
        return MenuComponent.SEARCHBOX_VISIBLE_ITEM_COUNT;
    }

    get VISIBLE_RECENT_AREALEN_COUNT() {
        return MenuComponent.VISIBLE_RECENT_AREALEN_COUNT;
    }
}
