import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpService2} from "../../../../shared/services/http-service-2.0/http2.0.service";
import {FormPostResult, HttpResult} from '../../../../shared/services/http-service-2.0/http.interface';
import {map} from "rxjs/operators";
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Injectable()
export class MapDeviceMappingService {
    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {
    }

    public getBBoxItems(bounds: google.maps.LatLngBounds): Observable<FormPostResult>{
        this.logger.log('[MapDeviceMappingService] ' + 'Get map items by bounding box')
        let southWest = bounds.getSouthWest()
        let northEast = bounds.getNorthEast()

        let postValues: any = {
            'bounds': {
                'south': southWest.lat(),
                'west': southWest.lng(),
                'north': northEast.lat(),
                'east': northEast.lng(),
            },
            'objectTypes': ['mast']
        };
        return this.httpService2.doFormPostRequest(`mapitem/device-mapping/list`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public mapDevice(baseObjectIdSource:number, baseObjectIdTarget:number, selectedOptionId:number): Observable<FormPostResult>{
        this.logger.log('[MapDeviceMappingService] - Save device info with baseObjectId: '+baseObjectIdSource);
        let postValues: any = {
            selectedOption: selectedOptionId,
        };
        return this.httpService2.doFormPostRequest(`mapitem/device-mapping/map/${baseObjectIdSource}/${baseObjectIdTarget}`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public getSelectedDeviceInfo(baseObjectIdSource:number, baseObjectIdTarget:number):Observable<HttpResult>{
        this.logger.log('[MapDeviceMappingService] - Get device info with baseObjectId: '+baseObjectIdSource+', to target device with baseObjectId: '+baseObjectIdTarget);
        return <Observable<HttpResult>>this.httpService2.doGetRequest(`mapitem/device-mapping/info/${baseObjectIdSource}/${baseObjectIdTarget}`);
    }

    public unmapDevice(baseObjectIdSource:number):Observable<HttpResult>{
        this.logger.log('[MapDeviceMappingService] - Unmap device with baseObjectId: '+baseObjectIdSource);
        return <Observable<HttpResult>>this.httpService2.doGetRequest(`mapitem/device-mapping/unmap/${baseObjectIdSource}`);
    }
}

